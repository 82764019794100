import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   function (error) {
//     const originalRequest = error.config;
//     if (error.response) {
//       if (error.response.status === 500) {
//         // SnackbarUtils.error('Something went wrong on server, please try again later.');
//       }
//       if (error.response.status === 401 && originalRequest.url === '/user/token') {
//         // SnackbarUtils.error('Session expired, please login again');
//         // setSession(null);

//         window.location.href = '/';
//         return Promise.reject(error);
//       }
//     }

//     if (error.response) {
//       if (error.response.status === 401 && !originalRequest._retry) {
//         originalRequest._retry = true;
//         const refreshToken = localStorage.getItem('refreshToken');
//         return axiosInstance.post('/user/token', { refreshToken }).then((res) => {
//           if (res.status === 202) {
//             const { accessToken, refreshToken } = res.data;
//             // setSession(accessToken, refreshToken);
//             originalRequest.headers.Authorization = `Bearer ${accessToken}`;
//             return axiosInstance(originalRequest);
//           }
//         });
//       }
//     }
//     return Promise.reject(
//       (error.response && error.response.data) || 'Something went wrong, please try later'
//     );
//   }
// );

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const postFetcher = async (args: string | [string, any, AxiosRequestConfig]) => {
  const [url, payload, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.post(url, payload, {
    ...config,
  });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    // me: '/api/auth/me',
    // me: '/admin/admin-users',
    me: '/profile',
    // login: '/api/auth/login',
    profile: '/profile',
    login: '/admin/login',
    register: '/api/auth/register',
    newPassword: '/login/change-password',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    filter: '/admin/blog/posts/filter',
    ideas: '/admin/lookups/activity-type',
    list: '/admin/blog/posts/filter',
    details: '/admin/blog/posts',
    latest: '/admin/blog/posts',
    search: '/admin/blog/posts',
    create: '/admin/blog/posts',
    update: '/admin/blog/posts',
    delete: '/admin/blog/posts',
    publish: '/admin/blog/posts',
    schedule: '/admin/blog/posts',
    status: '/admin/blog/posts',
    unschedule: '/admin/blog/posts/schedule',
    activity: '/admin/blog/posts',
    clone: '/admin/blog/posts',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  category: {
    list: '/admin/categories',
    details: '/admin/categories',
    create: '/admin/categories',
    update: '/admin/categories',
    search: '/api/product/search',
    all: '/admin/categories/all',
    delete: '/admin/categories',
    upload: '/admin/media',
  },
  city: {
    create: '/admin/cities',
    update: '/admin/cities',
    list: '/admin/cities',
    details: '/admin/cities',
    search: '/api/city/search',
    delete: '/admin/cities',
  },
  user: {
    list: '/admin/users',
    details: '/admin/users',
    update: '/admin/users',
    create: '/admin/users',
    delete: '/admin/users',
    search: '/api/product/search',
  },
  admin: {
    list: '/admin/admin-users',
    details: '/admin/admin-users',
    update: '/admin/admin-users',
    create: '/admin/admin-users',
    delete: '/admin/admin-users',
    resendPass: '/admin/login',
    changePassword: '/admin/login/change-password',
  },
  style: {
    list: '/admin/styles',

    create: '/admin/styles',
  },
  activityType: {
    list: '/admin/activity-types',
    details: '/admin/activity-types',
    update: '/admin/activity-types',
    create: '/admin/activity-types',
    delete: '/admin/activity-types',
  },
  dashboard: {
    totalViews: '/admin/dashboard/views',
    totalCityViews: '/admin/dashboard/city-views',
    totalCategoryViews: '/admin/dashboard/category-views',
    totalAuthorCount: '/admin/dashboard/authors/top',
    totalAuthorViews: '/admin/dashboard/author-views',
    recentBlog: '/admin/dashboard/blogs/recent',
    viewBlog: '/admin/dashboard/blog-views',
    googleA: '/admin/dashboard/analytics/report'
  },
};
