export const POST_PUBLISH_OPTIONS = [
  {
    value: 'PUBLISHED',
    label: 'Published',
  },
  {
    value: 'DRAFT',
    label: 'Draft',
  },
  {
    value: 'SCHEDULED',
    label: 'Scheduled',
  },
];

export const POST_SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];
