import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession, setTempSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';
import { useNavigate } from 'react-router-dom';
import { paths } from 'src/routes/paths';
import { useSearchParams } from 'src/routes/hooks';
import { PATH_AFTER_LOGIN } from 'src/config-global';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      // if (accessToken && isValidToken(accessToken)) {
      if (accessToken) {
        setSession(accessToken);

        const res = await axios.get(endpoints.auth.me);

        const { userInfo } = res.data;

        let user = {
          ...userInfo,
          displayName: userInfo.firstName,
          role: 'admin',
        };
        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (username: string, password: string) => {
    const data = {
      username,
      password,
    };

    const res = await axios.post(endpoints.auth.login, data);

    // console.log(res.data);
    // const { accessToken, expiresIn, user } = res.data;
    const { accessToken, expiresIn } = res.data;

    // const user = {
    //   id: '64dbc927b8c7724b7929313c',
    //   displayName: `Admin Rukhshan`,
    //   role: 'admin',
    // };

    // console.log(accessToken);

    // setSession(accessToken, expiresIn);

    const userData = await axios.get(`${endpoints.auth.me}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    let { userInfo } = userData.data;
    if (userInfo.updatePasswordRequired) {
      // Make user to reset password
      // window.location = paths.auth.jwt.resetPassword;
      setTempSession(accessToken);
      navigate(paths.auth.jwt.resetPassword);
    } else {
      setSession(accessToken, expiresIn);

      let roles = {
        SuperAdmin: 'admin',
      };

      let role = userData.data.userInfo.roles.map(
        (role: any) => roles[role.name as keyof typeof roles]
      );

      const user = {
        ...userData.data.userInfo,
        displayName: userData.data.userInfo.firstName,
        role: 'admin',
      };

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });

      navigate(returnTo || PATH_AFTER_LOGIN);
    }
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const res = await axios.post(endpoints.auth.register, data);

      const { accessToken, user } = res.data;

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const resetPassword = useCallback(async (oldPassword: string, newPassword: string) => {
    const data = {
      oldPassword,
      newPassword,
    };

    let tempAccessToken = sessionStorage.getItem('tempAccessToken');
    const res = await axios.put(endpoints.auth.newPassword, data, {
      headers: { Authorization: `Bearer ${tempAccessToken}` },
    });
    // navigate(paths.auth.jwt.login);
  }, []);
  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
      resetPassword,
    }),
    [login, logout, register, resetPassword, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
